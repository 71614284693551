.form-group {
    @apply flex flex-col mt-5;
}

.form-group input {
    @apply h-[50px] w-full mt-4 rounded-[32px] border border-solid border-[#7B7B7B] px-6; 
    font-size: 16px !important;
}

.form-group textarea {
    @apply h-[115px] w-full mt-4 rounded-[24px] border border-solid border-[#7B7B7B] px-6 py-3;
}

.google-map {
    @apply pb-[30%] relative aspect-square w-full;
}

.google-map iframe {
    @apply xl:h-2/3 h-full w-full left-0 top-0 absolute;
}

.react-international-phone-input-container {
    @apply !relative;
}

.react-international-phone-country-selector {
    @apply !absolute !top-6 left-6;
}

.react-international-phone-country-selector button {
    @apply !border-none hover:!bg-white;
}
