@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

@font-face {
  font-family: "SegoeUI";
  src: url("../src/assets/fonts/SEGOEUI.TTF");
}

@font-face {
  font-family: "Metropolis";
  src: url("../src/assets/fonts/metropolis.regular.otf") format("opentype");
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* purgecss start ignore */
@import '~react-loading-skeleton/dist/skeleton.css';
/* purgecss end ignore */

.product-category-wrapper {
  scrollbar-width: none;
  scrollbar-color: #DE4F2D;
}

.text-wrap {
  text-wrap: wrap;
}

.pre-wrap-custom {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: Roboto;
}

@media screen and (max-width: 480px) {
  .custom-scrollbar::-webkit-scrollbar {
    @apply h-px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    @apply bg-[#DE4F2D] rounded-md h-px z-10;
  }
}
